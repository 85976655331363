<template>

  <v-content>

    <v-container fluid>

      <core-view />

    </v-container>

  </v-content>
</template>

<script>
import CoreView from './CoreView'
  export default {
    name: 'DashboardCoreLeNotRequiredView',

    components: {
        'core-view': CoreView
    }
  }
</script>